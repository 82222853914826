<template>
  <div class="content-contaner">
    <el-row class="device-list">
      <el-row
        class="line-bot"
        type="flex"
        align="middle"
        justify="space-between"
      >
        <p class="title">我的收藏</p>
        <p class="handle">
          <span class="edit" @click="editFun">
            编辑
            <!-- <router-link :to="{ name: 'collect-edit' }">编辑</router-link> -->
          </span>
          <span class="clear" @click="toClear">全部清空</span>
        </p>
      </el-row>
      <!-- tab -->
      <div class="tab">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="技术中心收藏" name="first">
            <!-- 案例 -->
            <el-row class="case" v-if="articleList.length">
              <el-row
                class="item"
                type="flex"
                align="middle"
                v-for="(item, index) in articleList"
                :key="index"
              >
                <router-link
                  :to="{ name: 'case-detail', query: { id: item.id } }"
                >
                  <img :src="item.mainImg" alt="" />
                  <el-row class="contant-box">
                    <el-row class="box">
                      <p class="contant">
                        {{ item.essayTitle }}
                      </p>
                      <p class="date">{{ item.createTime }}</p>
                    </el-row>
                  </el-row>
                </router-link>
              </el-row>
            </el-row>
            <el-empty
              style="width: 100%"
              v-else
              description="暂无数据"
            ></el-empty>
          </el-tab-pane>
          <el-tab-pane label="机床商品收藏" name="second">
            <!-- 案例 -->
            <el-row class="case" v-if="goodList.length">
              <el-row
                class="item"
                type="flex"
                align="middle"
                v-for="(item, index) in goodList"
                :key="index"
              >
                <router-link :to="{ name: 'detail', query: { id: item.id } }">
                  <img :src="item.mainImg" alt="" />
                  <el-row class="contant-box">
                    <el-row class="box">
                      <p class="contant">
                        {{ item.briefTitle }}
                      </p>
                      <p class="date cr f22">
                        ￥ {{ item.showPrice.toFixed(2) }}
                      </p>
                    </el-row>
                  </el-row>
                </router-link>
              </el-row>
            </el-row>
            <el-empty
              style="width: 100%"
              v-else
              description="暂无数据"
            ></el-empty>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-row>
    <!-- 分页开始 -->
    <el-pagination
      background
      layout="prev, pager, next"
      :total="listTotal"
      :page-size="pageSize"
      :current-page="page"
      @current-change="pageChange"
      @prev-click="prevChange"
      @next-click="nextChange"
    >
    </el-pagination>
    <!-- 分页结束 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "first",

      // 分页相关数据
      page: 1,
      pageSize: 10,
      listTotal: 0,

      articleList: [], //技术中心收藏

      goodList: [], //二手机床收藏
    };
  },

  mounted() {},

  created() {
    // 用户收藏-分页列表查询
    this.userCollectionList();
  },

  methods: {
    editFun() {
      if (this.activeName == "first") {
        this.$router.push({ name: "collect-edit", query: { type: 1 } });
      } else {
        this.$router.push({ name: "collect-edit", query: { type: 2 } });
      }
    },
    // 全部清空
    toClear() {
      if (this.activeName == "first") {
        //技术中心收藏清空
        let idsArr = [];
        this.articleList.forEach((item) => {
          idsArr.push(item.id);
        });
        let ids = idsArr.join(",");
        console.log(ids, "ids");
        let paramData = {
          ids,
        };
        if (!ids) {
          this.$message.info("暂无收藏内容可删除");
          return;
        }
        this.$alert("确定全部清空吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          showCancelButton: true,
          callback: (action) => {
            // 确定  confirm  取消 cancel
            if (action == "confirm") {
              this.$API
                .userCollectionDelete(paramData)
                .then((res) => {
                  if (res.code == 1) {
                    this.$message({
                      message: "删除成功",
                      type: "success",
                    });
                    this.userCollectionList(); //刷新列表
                  } else {
                    this.$message.info(res.msg);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          },
        });
      }
      if (this.activeName == "second") {
        //二手机床收藏清空
        let idsArr = [];
        this.goodList.forEach((item) => {
          idsArr.push(item.id);
        });
        let ids = idsArr.join(",");
        console.log(ids, "ids");
        let paramData = {
          ids,
        };
        if (!ids) {
          this.$message.info("暂无收藏内容可删除");
          return;
        }
        this.$alert("确定全部清空吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          showCancelButton: true,
          callback: (action) => {
            // 确定  confirm  取消 cancel
            if (action == "confirm") {
              this.$API
                .userCollectionDelete(paramData)
                .then((res) => {
                  if (res.code == 1) {
                    this.$message({
                      message: "删除成功",
                      type: "success",
                    });
                    this.userCollectionList(); //刷新列表
                  } else {
                    this.$message.info(res.msg);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          },
        });
      }
    },
    // 用户收藏-分页列表查询
    userCollectionList() {
      let collectionType;
      if (this.activeName == "second") {
        collectionType = 1; //二手机床收藏
      } else {
        collectionType = 2; //技术中心收藏
      }
      let paramData = {
        pageNo: this.page,
        pageSize: this.pageSize,
        collectionType,
      };
      this.$API
        .userCollectionList(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.listTotal = Number(res.data.total);
            if (collectionType == 1) {
              //二手机床收藏
              this.goodList = res.data.records.map((item) => {
                return item.secondGoodsVO;
              });
              console.log(this.goodList, "=====================");
            }
            if (collectionType == 2) {
              //技术中心收藏
              this.articleList = res.data.records.map((item) => {
                return item.essayVO;
              });
              console.log(this.articleList, "-------------------");
            }
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 当前页码改变
    pageChange(page) {
      this.page = page;
      this.userCollectionList();
    },

    // 点击上一页
    prevChange() {},
    // 点击下一页
    nextChange() {},
    // tab点击
    handleClick(tab, event) {
      console.log(tab, event);
      this.userCollectionList();
    },
  },
};
</script>

<style lang="scss">
.content-contaner {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 32px;
  .device-list {
    margin-top: 0px;
    width: 100%;
    .line-bot {
      border-bottom: 1px solid #eeeeee;
    }
    .handle {
      cursor: pointer;
      .edit {
        font-size: 14px;
        color: #007bd9;
        margin-right: 32px;
      }
      .clear {
        font-size: 14px;
        color: #ff0015;
      }
    }
    .title {
      padding-bottom: 16px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      border: none;
    }
  }
  .tab {
    margin-top: 13px;
  }
  .el-tabs__active-bar {
    // width: 32px !important;
    background-color: #ff0015;
  }
  .el-tabs__item {
    color: #666666;
  }
  .el-tabs__item.is-active {
    color: #ff0015;
  }
  .case {
    background-color: #ffffff;
    width: 100%;
    box-sizing: border-box;
    .item {
      width: 540px;
      display: inline-block;
      padding-bottom: 24px;
      margin-bottom: 24px;
      cursor: pointer;
      border-bottom: 1px solid #eeeeee;
      transition: box-shadow 0.03s;
      &:hover {
        box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.12);
      }
      &:nth-child(2n + 1) {
        margin-right: 32px;
      }

      &:nth-child(2n) {
        margin-right: 40px;
      }
      img {
        width: 94px;
        height: 94px;
        margin-right: 20px;
        vertical-align: top;
      }
      .contant-box {
        display: inline-block;
      }
      .box {
        width: 400px;
        height: 94px;
        .contant {
          font-size: 14px;
          color: #333;
          @include dot(2);
        }
        .date {
          margin-top: 48px;
          position: relative;
          top: -10px;
        }
      }
    }
  }
  // 分頁
  .el-pagination {
    text-align: center;
    margin-bottom: 46px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333333;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: red;
    color: #ffffff;
  }
}
</style>